.container,
.ce-gallery {
    max-width: @grid-width;
    width: 100%;
    margin: 0 auto;
    padding: 0px @spacing;

    @media @dsm {
        padding: 0px @spacing--mobile;
    }
}

.frame-type-container-images .ce-gallery {
    padding: 0;
}

header .ce-gallery,
#parallax .ce-gallery {
    max-width: unset;
}
