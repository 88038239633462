.body.nightmode {
    @primary: #fff;
    @secondary: #000;
    @tertiary: #363636;

    @blue--light: #787b9e;

    @grey--dark: #1d1d1d;
    @grey--light: #363636;

    #title {
        &__content {
            .btn {
                background: @blue--light;

                &:hover {
                    background: @grey--light;

                    a {
                        border-color: @grey--light;
                    }
                }

                a {
                    color: @primary;
                    border-color: @blue--light;
                }
            }
        }

        &__pageTitle {
            color: @primary;
        }
    }

    .content {
        &__text {
            a {
                color: @blue--light;
            }
        }

        .frame-background {
            background: @secondary;
        }

        .grid__buttons {
            background: @secondary;

            .btn {
                background: @blue--light;
                color: @primary;

                &:hover {
                    background: @grey--light;

                    a {
                        border-color: @grey--light;
                    }
                }

                a {
                    color: @primary;
                    border-color: @blue--light;
                }
            }
        }

        .grid__images {
            &__column__container {
                background: @secondary;
            }
        }
    }

    #footer {
        background: var(--tertiary);

        &__content__items {
            a {
                color: @primary;
            }
        }
    }

    #warning {
        .content__text {
            a {
                color: @blue--light;
            }
        }
    }

    #warning__button {
        .content__text {
            a {
                color: @primary;
            }
        }
    }

    .hr {
        hr {
            background: @secondary;
        }

        svg {
            .colored {
                fill: @secondary;
            }
        }
    }

    #tools {
        .btn {
            background: @grey--light;
            color: @primary;
            border-color: transparent;

            &:hover {
                background: @blue--light;
            }
        }

        .tool__flex {
            .btn:nth-of-type(2) {
                border: inset 0px @primary;
                border-right-width: 2px;
                border-left-width: 2px;
            }
        }

        .btn.act {
            background: @blue--light;
        }
    }
}
