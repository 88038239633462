.offer-categories {

    .plugin__categories {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0 @spacing;
        margin-bottom: (@spacing * 1.5);
        padding: @spacing 0;
        padding-bottom: 0;

        span {
            font-family: "NotoSans bold";
        }

        @media @dmd {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}