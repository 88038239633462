#parallax {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    height: 70vh;
    &__wrap {
        display: block;
        position: relative;
        max-width: @screen-width;
        margin: 0px auto;
        padding: 0px @spacing;
        height: 70vh;
        img,
        div,
        figure {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0px;
            top: 0px;
            object-fit: cover;
        }
    }
    &__blur img {
        filter: blur(10px);
    }
    figure {
        overflow: hidden;
    }
    img {
        height: 120%;
        width: 120%;
        transform: translate(-10%, -10%);
    }
}
