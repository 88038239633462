.content {
    background: @secondary;

    .content__text,
    .ce-gallery .ce-column {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: @spacing;
        padding: (@spacing * 2.5) 0px;

        .text,
        .image {
            grid-column-start: 2;
            grid-column-end: 4;

            @media @dsm {
                grid-column-start: 1;
                grid-column-end: 5;
            }

            a {
                color: @farbe2;
            }

            ul {
                list-style: disc;
                margin: 10px 0px 10px 20px;
            }
        }

        .image {
            grid-column-end: 5;
            margin: 0;
            padding: 0;
        }
    }

    .frame-type-container-images .ce-gallery .ce-column {
        display: block;
        padding: 0;
    }

    .frame-background {
        background: @farbe3;
        max-width: @screen-width;
        position: relative;
        margin: 0px auto;
    }

    .image {
        display: flex;
        max-width: @grid-width;
        max-height: 374px;
        margin: (@spacing * 2.5) auto;
        padding: 0 @spacing;

        @media @dsm {
            padding: 0;
        }

        img {
            max-height: 100%;
            max-width: 100%;
            object-fit: cover;
        }
    }
}

.hr {
    display: flex;
    align-items: center;
    max-width: @grid-width;
    margin: auto;
    padding: (@spacing * 2) @spacing;

    hr {
        background: @farbe3;
        width: 100%;
        height: 2px;
        outline: none;
        border: 0px solid transparent;
    }

    svg {
        width: 45px;
        max-height: 24px;
        margin: 0px @spacing;

        .colored {
            fill: @farbe3;
        }
    }
}
