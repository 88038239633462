.btn {
    appearance: none;
    background: transparent;
    width: auto;
    padding: 0px;
    text-align: left;
    border: 0px solid transparent;
    border-radius: @border-radius;
    cursor: pointer;

    a {
        background-color: transparent;
        display: flex;
        align-items: center;
        padding: (@spacing / 4) (@spacing);
        border: 2px solid @farbe1;
        border-radius: @border-radius;
        transition: background-color 0.7s ease;
    }

    &:hover {
        background: @gradient;

        a {
            border: 2px solid transparent;
        }
    }

    &--icon {
        min-height: 72px;
        background: @w;
        font-weight: bold;
        font-family: 'NotoSans bold', sans-serif;

        a {
            min-height: 72px;
            color: @farbe1;
        }

        svg {
            margin-right: @spacing;
            width: auto;
            height: 30px;

            path {
                fill: @farbe1;
            }
        }

        &:hover {
            background: @gradient;

            a {
                border: 2px solid transparent;
            }
        }
    }
}

button.act {
    background: green;
}