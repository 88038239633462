body #content .frame-type-textpic {
    .ce-gallery[data-ce-columns="1"] {
        max-width: @grid-width;
        width: 100%;
        margin: 0 auto;
        padding: 0 @spacing;
        @media @dxs {
            padding: 0px @spacing--mobile;
        }
        .ce-outer {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: @spacing;
            .ce-inner {
                grid-column-start: 2;
                grid-column-end: 4;
                @media @dxs {
                    grid-column-start: 1;
                    grid-column-end: 5;
                }
            }
            .image {
                display: block;
                max-width: inherit;
                max-height: inherit;
                padding: 0px;
                margin: 0px;
            }
        }
    }
    .ce-gallery[data-ce-columns="2"], .ce-gallery[data-ce-columns="3"] {
        max-width: @grid-width;
        width: 100%;
        margin: 0 auto;
        padding: 0 @spacing;
        @media @dxs {
            padding: 0px @spacing--mobile;
        }
        .ce-outer {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: @spacing;
            .ce-inner {
                grid-column-start: 2;
                grid-column-end: 5;
                @media @dxs {
                    grid-column-start: 1;
                    grid-column-end: 5;
                }
                .ce-row {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-gap: @spacing;
                    margin-bottom: @spacing;
                    .image {
                        display: block;
                        max-width: inherit;
                        max-height: inherit;
                        padding: 0px;
                        margin: 0px;
                        img {
                            background: #FFFFFF;
                            box-shadow: 0px 3px 6px #00000029;
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
    }
    .content__header {
        max-width: @grid-width;
        width: 100%;
        margin: 0 auto;
        padding: 0 @spacing;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: @spacing;
        @media @dxs {
            padding: 0px @spacing--mobile;
        }
        >* {
            grid-column-start: 2;
            grid-column-end: 4;
            @media @dxs {
                grid-column-start: 1;
                grid-column-end: 5;
            }
        }
    }
    .ce-bodytext {
        max-width: @grid-width;
        width: 100%;
        margin: 0 auto;
        padding: 0 @spacing;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: @spacing;
        @media @dxs {
            padding: 0px @spacing--mobile;
        }
        >* {
            grid-column-start: 2;
            grid-column-end: 4;
            @media @dxs {
                grid-column-start: 1;
                grid-column-end: 5;
            }
        }
    }
}