#tools {
    position: absolute;
    left: @spacing;
    width: 220px;

    @media only screen and (min-width: @grid-width) {
        left: calc((~'100vw - @{grid-width}') / 2 + @spacing);
    }

    &.visible {
        display: block;
    }

    &.fixed {
        top: 100px;
        position: fixed;
    }

    .tool {
        margin-bottom: @spacing;

        &:last-child {
            margin-bottom: 0px;
        }

        button {
            position: relative;
            font-family: 'NotoSans';

            i {
                margin-right: 15px;
            }
        }

        .btn {
            font-weight: 300;
        }

        &__title {
            margin-bottom: (@spacing / 4);
            font-weight: 900;
        }

        &__flex {
            display: flex;

            .btn {
                padding: 4px 15px;
                border-radius: 0px;
            }

            .btn:first-child {
                border-right: 0px;
                border-radius: 4px 0px 0px 4px;
            }

            .btn:last-child {
                border-left: 0px;
                border-radius: 0px 4px 4px 0px;
            }
        }

        .week {
            .btn {
                text-align: center;
            }
        }
    }

    .btn {
        background: @w;
        color: @farbe1;
        display: block;
        width: 100%;
        margin-bottom: 6px;
        padding: 4px 12px;
        border: 2px solid @farbe1;

        &:hover,
        &.act {
            background-color: @farbe1;
            color: @w;
        }
    }
}

#tool__toggler {
    display: none;
    background: @gradient;
    transform: rotate(-90deg);
    position: absolute;
    left: 187px;
    top: 63px;
    border-radius: 0px 0px 4px 4px;
    padding: 0px 10px;
    hyphens: none;

    @media @dmd {
        display: block;
    }
}

#tools {
    @media @dmd {
        padding: 10px 15px 0px 15px;
        width: 250px;
        left: -250px;
        background: @w;

        &.mob__show {
            left: 0px;
        }
    }

    @media @dxs {
        box-shadow: @drop;
    }
}
