#warning__button {
    background: @secondary;
    position: absolute;
    top: 70vh;
    right: 50%;
    min-height: 72px;
    display: flex;
    box-shadow: @drop;
    border-radius: @border-radius;
    transform: translate((@grid-width / 2), -36px);
    transition: transform 0.3s ease, opacity 0.7s ease-in-out;
    cursor: pointer;
    opacity: 1;
    color: @farbe1;

    @media only screen and (min-width: @grid-width) {
        margin-right: @spacing;
    }

    &__icon {
        background: @farbe1;
        display: block;
        width: 72px;
        height: 72px;
        border-radius: @border-radius;

        p {
            color: @w;
            width: 72px;

            font-size: 54px;
            font-weight: bold;
            line-height: 72px;
            text-align: center;
        }

        .container {
            background: @w;
            border-radius: @border-radius;
        }
    }

    .content__text {
        display: flex;
        align-items: center;
        min-height: 72px;

        .text {
            .subheader,
            p {
                display: none !important;
            }

            h2 {
                padding: 0;
                font-size: 1.4em;
                margin: 0px;
            }
        }
    }

    &:hover {
        transform: translate((@grid-width / 2), -41px);
    }
    @media (max-width: (@grid-width - @spacing)) {
        transform: translate(0, -36px);
        &:hover {
            transform: translate(0, -41px);
        }
        right: @spacing;
    }
    &.hide {
        opacity: 0;
    }
}
#warning {
    background: @secondary;
    padding: (@spacing * 2.5) 0;
    .content__text {
        position: relative;
        display: block;
        padding: (@spacing * 1.5) @spacing;
        border-radius: @border-radius;
        box-shadow: @drop;

        a {
            color: @farbe2;
            font-weight: bold;
        }

        h2 {
            padding: 0;
        }

        p {
            max-width: 600px;
        }

        .subheader {
            // display: none;
            margin-bottom: (@spacing * 1.5);
        }
    }

    #Hinweis {
        position: absolute;
        top: -120px;
        visibility: hidden;
    }
}
