.body {

    &.scale150 {
        .btn--icon svg {
            height: 36px;
        }

        .content__header h1 {
            font-size: 38px;
            line-height: 1.8em;
            word-wrap: break-word;
        }

        .content__header h2 {
            font-size: 34px;
            line-height: 1.4em;
            word-wrap: break-word;
        }

        .content__header h3,
        .card-container h3 {
            font-size: 30px;
            line-height: 1.4em;
            word-wrap: break-word;
        }

        #title #title__pageTitle,
        .frame-type-text,
        .frame-type-textpic,
        .frame-type-button,
        .frame-type-offercategories,
        .card-container__card__content p,
        .plugin__categories span,
        .plugin__categories .cat,
        .frame-type-gshncalendar_pi1,
        .frame-type-contact {
            font-size: 24px;
            line-height: 1.2em;
        }

        .card-container {
            grid-template-columns: repeat(2, 1fr);

            @media @dmd {
                grid-template-columns: 1fr;
            }
        }
    }

    &.scale200 {
        .btn--icon svg {
            height: 42px;
        }

        .content__header h1 {
            font-size: 46px;
            line-height: 2em;
            word-wrap: break-word;
        }

        .content__header h2,
        .clearfix h2 {
            font-size: 42px;
            line-height: 1.4em;
            word-wrap: break-word;
        }

        .content__header h3,
        .card-container h3 {
            font-size: 38px;
            line-height: 1.4em;
            word-wrap: break-word;
        }

        #title #title__pageTitle,
        .frame-type-text,
        .frame-type-textpic,
        .frame-type-button,
        .frame-type-offercategories,
        .card-container__card__content p,
        .plugin__categories span,
        .plugin__categories .cat,
        .frame-type-gshncalendar_pi1,
        .frame-type-contact {
            font-size: 32px;
            line-height: 1.4em;
        }

        .card-container {
            grid-template-columns: 1fr;
        }
    }
}