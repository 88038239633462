.plugin {
    display: block;
    position: relative;
    margin-bottom: (@spacing * 2.5);

    &__container {
        grid-column-start: 2;
        grid-column-end: 5;
        padding: @spacing 0 (@spacing * 5) 0;

        @media @dxl {
            grid-template-columns: 1fr 1fr;
        }

        @media @dsm {
            grid-column-start: 1;
            grid-template-columns: 1fr;
        }
    }

    &__tabs {
        width: 100%;
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        height: 40px;
        background: @secondary;

        .container {
            display: block;
            position: relative;
            z-index: 10;

            button {
                background: #fff;
                color: @farbe2;
                padding: (@spacing / 4) (@spacing);

                font-weight: bold;

                border-color: transparent;
                border-radius: @border-radius;

                &:first-child {
                    margin-right: (@spacing / 2);
                }

                &:active,
                &:hover {
                    background: @farbe1;
                    color: #fff;
                    box-shadow: @drop;
                }
            }

            .active {
                background-color: @farbe1;
                color: #fff;
                box-shadow: @drop;
            }
        }
    }

    &__categories {
        &.act {
            display: block;
        }

        @media @dxs {
            flex-flow: row;
            flex-wrap: wrap;
        }

        .cat {
            display: flex;
            align-items: center;
            margin: (@spacing / 4);
            margin-left: 0;

            @media @dxs {
                flex: 0 0 (50% - (@spacing / 2));
            }

            input[type='checkbox'] {
                -webkit-appearance: none;
                -moz-appearance: none;

                outline: none;
                background: @farbe2;
                position: relative;
                display: inline-block;
                width: 25px;
                height: 25px;
                margin: 0;
                cursor: pointer;
                border-radius: @border-radius;
                width: 25px;
                border-radius: 4px;
                transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

                &:after {
                    content: '';
                    display: block;
                    left: 8px;
                    top: 2px;
                    position: absolute;
                    transform: rotate(45deg);
                    transition: opacity 0.2s;
                }

                &:checked {
                    background-color: @farbe1;
                }

                &:hover {
                    background: @farbe1;
                }

                &:checked {
                    &:after {
                        opacity: 1;
                        width: 8px;
                        height: 16px;
                        border: 3px solid #fff;
                        border-top: 0;
                        border-left: 0;
                        left: 8px;
                        top: 2px;
                    }
                }

                & + label {
                    display: inline-block;
                    cursor: pointer;
                    padding-left: 12px;
                }
            }
        }
    }

    &__tools {
        display: none;

        &.act {
            display: inline-block;
        }
    }

    &__wrap {
        position: relative;
        margin: 0px auto;
        max-width: @screen-width;
        padding: 0 @spacing;
        background: @farbe3;

        @media @dsm {
            padding: 0;
        }
    }

    .container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
    }

    .card-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: @spacing;

        @media @dlg {
            grid-template-columns: repeat(2, 1fr);
        }

        @media @dsm {
            grid-template-columns: repeat(1, 1fr);
        }

        &__card {
            display: block;
            position: relative;
            background: @secondary;
            border-radius: @border-radius;
            box-shadow: @drop;
            overflow: hidden;
            transition: transform 0.3s ease;

            &.hidden {
                display: none;
            }

            &:hover {
                transform: translateY(-10px);
            }

            &__image {
                position: relative;
                display: block;
                width: 100%;
                height: 0px;
                padding-bottom: 46.35761589%;

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__content {
                padding: (@spacing * 1.5) @spacing;

                h3 {
                    margin-bottom: @spacing;
                }
            }
        }
    }


}
