.contact {
    &__image {
        position: relative;
        display: block;
        width: 100%;
        height: 150px;
        max-height: 150px;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        display: flex;
        flex-flow: column;
        grid-area: contact;
        height: calc(100% - 150px);
        padding: (@spacing * 1.5) @spacing;

        a {
            color: @farbe2;
        }

        p {
            margin-top: @spacing;
        }

        p:nth-of-type(4n + 2) {
            margin-bottom: @spacing;
        }

        p:nth-of-type(4n + 3) {
            margin-top: auto;
        }

        .position {
            margin-top: -@spacing;
        }
    }
}