.nav-opener {
    position: relative;
    appearance: none;
    display: none;
    width: 38px;
    height: 38px;
    margin-left: auto;
    cursor: pointer;
    border: 2.2px solid @farbe1;
    border-radius: 50%;
    background: none;
    z-index: 100;

    @media @dmd {
        display: flex;
    }

    &__burger {
        position: absolute;
        top: 50%;
        right: 50%;
        width: 26px;
        height: 20px;
        overflow: hidden;
        transition: transform 0.2s ease;
        transform: translate(50%, -50%);

        span {
            position: absolute;
            left: 3px;
            width: 20px;
            height: 3px;
            background: @farbe1;
            transition: transform 0.2s ease;
            border-radius: 5px;

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2) {
                top: 50%;
                left: 0;
                width: 26px;
                transform: translateY(-50%);
                opacity: 1;
                transition: transform 0.3s ease, opacity 0.1s ease;
            }

            &:nth-child(3) {
                bottom: 0;
            }
        }

        &--open span {
            overflow: visible;

            &:nth-child(1) {
                transform: rotate(45deg) translate(6px, 6px);
            }

            &:nth-child(2) {
                transform: translateX(-30px);
                opacity: 0;
            }

            &:nth-child(3) {
                transform: rotate(-45deg) translate(6px, -6px);
            }
        }
    }
}

.main-navigation {
    margin-left: auto;

    @media @dmd {
        position: absolute;
        top: -30px;
        left: -@spacing;
        width: 100vw;
        height: 100vh;
        background: @w;
        transform: translateX(100%);
        transition: transform 0.2s ease;
        overflow-y: scroll;
    }

    @media @dsm {
        left: -@spacing--mobile;
    }

    a {
        color: @b;
    }

    .navbar-nav {
        display: flex;
        align-items: flex-start;

        @media @dmd {
            flex-flow: column;
            width: 100%;
            max-height: 100%;
            padding: (@spacing * 5) @spacing @spacing @spacing;
        }

        @media @dsm {
            padding: (@spacing * 5) @spacing--mobile;
        }

        .nav-item {
            position: relative;
            margin-right: (@spacing * 2);
            cursor: pointer;

            @media @dmd {
                margin-bottom: @spacing;
            }

            a {
                padding: (@spacing / 4) 0px;
                display: block;
            }

            &--dropdown {
                text-align: right;
                padding: (@spacing / 4) 0px;
                border-bottom: 2px solid transparent;

                @media @dmd {
                    text-align: left;
                }

                &:hover {
                    border-color: @b;
                }

                .btn--nav-item {
                    border-radius: 0;

                    &:after {
                        position: relative;
                        top: -1px;
                        right: 0;
                        content: '\25BC';
                        font-size: 0.8em;
                        margin-left: 6px;
                    }

                    &:hover {
                        background: unset;
                    }
                }

                &--active {
                    &:hover {
                        border-color: transparent;
                    }

                    .dropdown-menu {
                        display: flex;
                        visibility: unset;
                    }

                    .btn--nav-item::after {
                        content: '\25B2';
                    }
                }
            }

            &--link {
                border-bottom: 2px solid transparent;

                &:hover,
                &.active {
                    border-color: @b;
                }
            }
        }

        .dropdown-menu {
            position: absolute;
            right: @spacing;
            display: none;
            flex-flow: column;
            align-items: flex-end;
            margin-top: @spacing;
            overflow: hidden;
            visibility: hidden;

            @media @dmd {
                position: relative;
                right: unset;
                align-items: flex-start;
                margin-bottom: (@spacing * 4);
            }

            @media @dsm {
                margin-bottom: @spacing--mobile;
            }

            &__background {
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                background-color: @w;
                z-index: -1;
            }

            &__item {
                display: inline-block;
                margin: (@spacing / 4) 0;

                &:first-child {
                    margin-top: calc(@spacing * 2);
                }

                .nav-item--link {
                    word-break: unset;
                    white-space: nowrap;

                    @media @dmd {
                        text-align: left;
                        white-space: unset;
                        hyphens: auto;
                        word-break: normal;
                    }
                }
            }
        }
    }
}

.large-navigation {
    @media @dlg {
        .nav-opener {
            display: flex;
        }

        .main-navigation {
            position: absolute;
            top: -30px;
            left: -@spacing;
            width: 100vw;
            height: 100vh;
            background: @w;
            transform: translateX(100%);
            transition: transform 0.2s ease;
            overflow-y: scroll;

            .navbar-nav {
                flex-flow: column;
                width: 100%;
                max-height: 100%;
                padding: (@spacing * 5) @spacing @spacing @spacing;

                .nav-item {
                    margin-bottom: @spacing;

                    &--dropdown {
                        text-align: left;
                    }
                }

                .dropdown-menu {
                    position: relative;
                    right: unset;
                    align-items: flex-start;
                    margin-bottom: (@spacing * 4);

                    .nav-item--link {
                        text-align: left;
                        white-space: unset;
                        hyphens: auto;
                        word-break: normal;
                    }
                }
            }
        }
    }

    @media @dsm {
        .main-navigation {
            left: -@spacing--mobile;

            .navbar-nav {
                padding: (@spacing * 5) @spacing--mobile;
            }
        }

    }
}