#locations,
#filteredLocations {
    &__wrap {
        background: @farbe2;
        position: relative;
        display: block;
        width: 100%;
        height: auto;
    }

    &__container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 16px;
        grid-row-gap: 40px;
        padding-bottom: (@spacing * 2);

        @media @dmd {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media @dsm {
            grid-template-columns: 1fr 1fr;
        }

        @media @dxs {
            grid-template-columns: 1fr;
        }
    }

    &.scale150 {
        #locations__container {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    &.scale200 {
        #locations__container {
            grid-template-columns: 1fr 1fr 1fr;
            .location {
                grid-column-start: 2;
                grid-column-end: 4;
            }
        }
    }

    &__tabs {
        width: 100%;
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        height: 70px;
        background: @farbe2;

        .container {
            display: block;
            position: relative;
            z-index: 10;
        }
    }
    &__tab {
        padding-top: 60px;

        &__locations {
            display: none;
            flex-flow: column;

            @media @dxs {
                flex-flow: row;

                flex-wrap: wrap;
            }

            h4 {
                margin-top: (@spacing * 2);
                font-size: 16px;
                font-weight: bold;

                @media @dxs {
                    flex: 1 0 100%;
                }
            }

            &__tools {
                display: none;
                &.act {
                    display: inline-block;
                }
            }
        }
    }

    #filteredLocations__wrap {
        background: unset;

        .location {
            &:first-child,
            &:nth-child(4) {
                grid-column-start: 2;

                @media @dmd {
                    grid-column-start: unset;
                }
            }
        }
    }

    .location {
        display: block;
        position: relative;
        background: @w;
        border-radius: @border-radius;
        box-shadow: @drop;
        overflow: hidden;
        transition: transform 0.3s ease;

        &.hidden {
            display: none;
        }

        &:hover {
            transform: translateY(-10px);
        }

        &__image {
            position: relative;
            display: block;
            width: 100%;
            height: 140px;
            padding-bottom: 46.35761589%;
        }

        &__content {
            padding: (@spacing * 1.5) @spacing;

            h3 {
                margin-bottom: @spacing;
            }
        }
    }
}
