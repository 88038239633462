@font-face {
    font-family: "NotoSans";
    font-display: block;
    font-weight: 300;
    src: url("https://cdn.mmsrv.de/fonts/NotoSans/Regular.ttf")
        format("truetype");
}

@font-face {
    font-family: "NotoSans bold";
    font-display: block;
    font-weight: 900;
    src: url("https://cdn.mmsrv.de/fonts/NotoSans/Bold.ttf")
        format("truetype");
}

@font-face {
    font-family: "NotoSans italic";
    font-display: block;
    font-weight: 300;
    src: url("https://cdn.mmsrv.de/fonts/NotoSans/Italic.ttf")
        format("truetype");
}
