.header {
    color: @b;
    position: fixed;
    display: block;
    z-index: 999;
    top: 0px;
    left: 0px;
    right: 0px;
    background: transparent;
    transition: background 0.3s ease;
    font-size: 18px;
    line-height: 24px;
    font-family: "NotoSans bold", sans-serif;

    a {
        color: @b;
    }

    &:hover {
        background: @w;
    }

    &.bg {
        .frame-type-image {
            opacity: 1;
        }

        &:hover {
            figure {
                opacity: 0;
            }
        }
    }

    &.white {
        background: @w;
    }

    &__wrap {
        position: relative;
        margin: 0px auto;
        max-width: @screen-width;
        padding: 30px @spacing;

        @media @dxs {
            padding: @spacing @spacing--mobile;
        }
    }

    &__content {
        position: relative;
        display: flex;
        justify-content: space-between;
        max-width: @grid-width;
        margin: 0px auto;

        @media @ugw {
            padding: 0 @spacing;
        }

        .logo {
            position: absolute;
            display: flex;
            align-items: center;
            padding: 5px 0;
            z-index: 1000;
        }
    }

    .frame-type-image {
        opacity: 0;
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        top: 0px;
        background: @w;

        img,
        div,
        figure {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0px;
            top: 0px;
            object-fit: cover;
        }

        figure {
            overflow: hidden;
            transition: opacity 0.3s ease;
            opacity: 1;
        }

        img {
            height: 120%;
            width: 120%;
            filter: blur(10px);
            transform: translate(-10%, -10%);
        }
    }

    &:hover {
        background: @w;
    }
}