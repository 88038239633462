#footer {
    background: @gradient;
    max-width: @screen-width;
    margin: auto;
    margin-top: (@spacing * 2.5);
    padding-top: @spacing;

    a {
        color: @primary;
    }

    &__wrap {
        max-width: @grid-width;
        margin: 0px auto;
        padding: @spacing;

        @media @dxs {
            padding: @spacing--mobile;
        }
    }

    &__logos {
        background: @w;

        &__wrap {
            display: flex;
            justify-content: space-between;
            max-width: @grid-width;
            margin: auto;
            padding: (@spacing * 2) @spacing;

            @media @dsm {
                flex-flow: column;
                align-items: flex-start;
            }

            a {
                margin-right: (@spacing * 2);

                @media @dsm {
                    margin: @spacing 0;
                    padding: @spacing;
                }

                img {
                    object-fit: contain;
                    height: 45px;
                    width: auto;
                    max-width: 100%;
                }
            }

            .no-link {
                cursor: default;
            }

            .footer__logos__left,
            .footer__logos__right {
                display: flex;
                align-items: center;
                max-width: 100%;

                @media @dsm {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }
    }

    &__content {
        display: flex;
        width: 100%;
        margin: 0 auto;
        margin-top: (@spacing * 1.5);

        @media @dxs {
            flex-flow: column;
        }

        &__headline {
            font-weight: bold;
            margin-bottom: @spacing;
        }

        &__left,
        &__right {
            flex: 0 1 50%;
            margin-right: (@spacing * 2);

            @media @dxs {
                flex: 1 0 100%;
            }
        }

        &__items {
            display: flex;
            flex-wrap: wrap;

            @media @dxs {
                flex-flow: column;
            }

            a {
                flex: 0 1 40%;
                margin-bottom: 10px;
            }
        }

        &__right {
            @media @dxs {
                margin-top: (@spacing * 2);
            }

            #footer__content__container {
                display: flex;

                @media @dxs {
                    flex-flow: column;
                }

                #footer__content__items {
                    width: 50%;

                    a {
                        flex: 0 0 100%;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    &__copy {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: @spacing;
        padding-top: (@spacing * 4);
        font-size: 12px;

        .logo {
            margin-left: 10px;
            max-width: 160px;
        }

        @media @dxs {
            flex-flow: column;
            justify-content: flex-start;
            align-items: flex-start;
            max-width: 260px;
            font-size: 16px;
            line-height: 26px;
            padding: @spacing--mobile;
            padding-top: (@spacing * 4);

            .logo {
                margin-top: @spacing;
                margin-left: 0;
            }
        }
    }
}