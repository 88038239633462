.grid__images {
    display: grid;
    grid-gap: @spacing;
    grid-template-columns: repeat(4, 1fr);
    max-width: @grid-width;
    margin: (@spacing * 2.5) auto;
    padding: 0 @spacing;

    @media @dmd {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        padding: 0;
    }

    &__column {
        position: relative;

        div,
        figure,
        img {
            width: 100%;
            height: 100%;
        }

        figure {
            margin: 0 !important;
            padding: 0 !important;
        }

        img {
            object-fit: cover;
        }

        &__container {
            background: @farbe3;
            position: relative;
            height: 100%;
            width: 100%;
        }

        @media @dmd {
            &:nth-child(1) {
                display: none;
            }
        }
    }
}

.grid__buttons {
    background: @farbe3;
    margin: (@spacing * 2.5) 0;

    &__wrap {
        display: grid;
        grid-gap: @spacing;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        max-width: @grid-width;
        margin: 0px auto;
        padding: @spacing;

        @media @dsm {
            grid-template-columns: 1fr;
        }
    }

    &__column {
        position: relative;

        &:nth-child(1) {
            grid-column-start: 2;
            grid-column-end: 4;
        }

        @media @dsm {
            &:nth-child(1) {
                grid-column-start: 1;
                grid-column-end: 2;
            }
        }
    }
}