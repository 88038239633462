#title {
    color: @primary;
    position: relative;
    display: block;
    width: 100%;
    min-height: 30vh;
    &.small {
        min-height: 200px;
    }
    &__spacer {
        height: 70vh;
    }
    &__content {
        background: @secondary;
    }
    &__pageTitle {
        color: @primary;
        display: block;
        padding: (2 * @spacing) 0px (1 * @spacing) 0px;
        cursor: default;

        @media @dxs {
            padding-top: (@spacing * 4);
        }
    }

    h1 {
        @media @uxl {
            max-width: 50%;
        }
    }
    .btn {
        background: @w;
        &:hover {
            background: @gradient;
        }
    }
    .row {
        display: flex;

        @media @dxs {
            flex-flow: column;
        }

        .column {
            width: 100%;
            margin: 0px (@spacing / 2);

            &:first-child {
                margin: 0px (@spacing / 2) 0px 0px;
            }

            &:last-child {
                margin: 0px 0px 0px (@spacing / 2);
            }

            @media @dxs {
                margin: (@spacing / 2) 0;

                &:first-child,
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}
