@import (inline) '../css/normalize-8.0.0.css';
@import '_mixins/_mixins.less';
@import '_layout/_all';
@import '_components/_components.less';
@import '_mixins/nightmode';

*,
*:before,
*:after {
    box-sizing: inherit;
}

:focus-visible {
    background: @grey--light;
    border-color: @grey--light;
    outline: 2px solid @grey--light;
}

:focus:not(:focus-visible) {
    outline: none;
}

html,
.body {
    box-sizing: border-box;
    height: auto;

    @media @dxs {
        hyphens: auto;
    }
}

.body {
    position: relative;
    display: block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: NotoSans, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    color: @primary;
    background: @secondary;
    max-width: 100vw;

    &.body--no-scroll {
        overflow: hidden;
    }
}

a {
    text-decoration: none;
    color: @primary;
}

a,
button,
input[type='submit'] {
    cursor: pointer;
}

img {
    width: 100%;
    height: auto;
    display: block;
}

h1,
h2,
h3 {
    margin: 0px;
    font-family: 'NotoSans bold', sans-serif;
    text-decoration: none;
    line-height: 1.4em;
    font-weight: bold;
}

h1 {
    font-size: 2em;
    margin-bottom: (@spacing / 2 * 3);

    span {
        display: block;
        font-size: 0.5em;
    }
}

h2 {
    font-size: 1.8em;
    margin-bottom: @spacing;
}

h3 {
    font-size: 1.4em;
    margin-bottom: @spacing;
}

p {
    margin: 0px;
    margin-bottom: 0px;
}

b,
strong {
    font-weight: 900;
    line-height: 1.2em;
}

em {
    font-style: italic;
}

::selection {
    background: @farbe3;
}

:focus {
    outline: 2px transparent solid;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 150px @tertiary inset !important;
    -webkit-box-shadow: 0 0 0 150px @tertiary inset !important;
}

main {
    display: block;
    position: relative;
    line-height: 26px;
    max-width: @screen-width;
    margin: 0px auto;
    background: @secondary;
}

.flex__spacer {
    width: 100%;
}

.frame-type-gshncontact_pi1,
.frame-type-gshncontact_pi2,
.frame-type-gshncalendar_pi1 {
    & > .content__header {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: @spacing;
        width: 100%;
        max-width: @grid-width;
        margin: 0 auto;
        padding: 0px @spacing;

        h1,
        h2,
        h3,
        h4 {
            grid-column-start: 2;
            grid-column-end: 5;

            @media @dxs {
                grid-column-start: 1;
            }
        }

        h2 {
            padding-top: (@spacing * 2.5);
        }
    }
}

.p0 {
    padding: 0 !important;
}