.calendar {

    .plugin__container {
        gap: 30px;
        min-height: 400px;
        grid-column-end: 4;
    }

    #week,
    #events {
        display: none;

        &.show {
            display: block;
        }
    }

    &__tab__dates {
        margin-bottom: (@spacing * 1.5);

        .tool {
            display: flex;
            align-items: center;
            gap: 18px;
            margin-bottom: @spacing;

            @media @dmd {
                align-items: flex-start;
                flex-direction: column;
            }

            button {
                position: relative;
                font-family: 'NotoSans';

                i {
                    margin-right: 15px;
                }
            }

            &__title {
                margin-bottom: 0;
            }

            .btn {
                display: flex;
                width: fit-content;
                padding: 4px 15px;
                background: @w;
                color: @farbe1;
                border: 2px solid @farbe1;
                text-align: center;

                &:hover,
                &.act {
                    background-color: @farbe1;
                    color: @w;
                }
            }

            &__flex {
                display: flex;

                .btn {
                    border-radius: 0px;
                    // line-height: 17px;
                }

                .btn:first-child {
                    border-right: 0px;
                    border-radius: 4px 0px 0px 4px;
                }

                .btn:last-child {
                    border-left: 0px;
                    border-radius: 0px 4px 4px 0px;
                }
            }
        }
    }

    &.scale150 {
        #calendarDates {
            h3 {
                font-size: 30px;
                line-height: 1.4em;
                word-wrap: break-word;
            }

            p {
                font-size: 24px;
                line-height: 1.2em;
            }
        }
    }

    &.scale200 {
        #calendarDates {
            h3 {
                font-size: 38px;
                line-height: 1.6em;
                word-wrap: break-word;
            }

            p {
                font-size: 32px;
                line-height: 1.4em;
            }
        }
    }
}

.date {
    background: @secondary;
    display: block;
    position: relative;
    margin-bottom: @spacing;
    border-radius: @border-radius;
    box-shadow: @drop;
    overflow: hidden;
    transition: transform 0.3s ease;
    z-index: 200;

    &.hasDate:hover {
        transform: translateY(-10px);

        .date__line {
            cursor: pointer;
        }
    }

    &.show {
        .date__line {
            background: @gradient;
        }

        .date__content {
            height: auto;
            transition: height 0.3s linear, padding 0.3s linear;
            padding: (@spacing * 1.5) @spacing;
        }
    }

    &__line {
        background: @secondary;
        display: flex;
        justify-content: space-between;
        padding: (@spacing / 4) @spacing;
        z-index: 100;
        cursor: default;
    }

    &__content {
        display: block;
        height: 0;
        overflow: hidden;
        transition: height 0.3s linear, padding 0.3s linear;
        padding: 0 @spacing;

        a {
            color: @farbe2;
        }

        h3 {
            margin-bottom: 0;
        }

        &__time {
            margin-bottom: @spacing;
        }
    }

    .bold {
        font-family: 'NotoSans bold';
    }
}

.no-hover {
    background: @farbe1 !important;
    cursor: default;
}

// Loading Spinner
.spinner__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .spinner {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .spinner div {
        animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform-origin: 40px 40px;
    }

    .spinner div:after {
        content: ' ';
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #fff;
        margin: -4px 0 0 -4px;
    }

    .spinner div:nth-child(1) {
        animation-delay: -0.036s;
    }

    .spinner div:nth-child(1):after {
        top: 63px;
        left: 63px;
    }

    .spinner div:nth-child(2) {
        animation-delay: -0.072s;
    }

    .spinner div:nth-child(2):after {
        top: 68px;
        left: 56px;
    }

    .spinner div:nth-child(3) {
        animation-delay: -0.108s;
    }

    .spinner div:nth-child(3):after {
        top: 71px;
        left: 48px;
    }

    .spinner div:nth-child(4) {
        animation-delay: -0.144s;
    }

    .spinner div:nth-child(4):after {
        top: 72px;
        left: 40px;
    }

    .spinner div:nth-child(5) {
        animation-delay: -0.18s;
    }

    .spinner div:nth-child(5):after {
        top: 71px;
        left: 32px;
    }

    .spinner div:nth-child(6) {
        animation-delay: -0.216s;
    }

    .spinner div:nth-child(6):after {
        top: 68px;
        left: 24px;
    }

    .spinner div:nth-child(7) {
        animation-delay: -0.252s;
    }

    .spinner div:nth-child(7):after {
        top: 63px;
        left: 17px;
    }

    .spinner div:nth-child(8) {
        animation-delay: -0.288s;
    }

    .spinner div:nth-child(8):after {
        top: 56px;
        left: 12px;
    }

    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}