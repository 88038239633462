.faqs {
    padding: (@spacing * 2.5) 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: @spacing;
    max-width: @grid-width;
    width: 100%;
    margin: 0 auto;
    padding: 0px @spacing;
    .faq {
        background: @w;
        border: 2px solid @farbe1;
        min-height: 70px;
        border-radius: @border-radius;
        color: @farbe1;
        grid-column-start: 2;
        grid-column-end: 5;
        &:hover {
            background: @gradient;
        }
        &__header {
            display: flex;
            justify-content: space-between;
            font-weight: bold;
            font-family: "NotoSans bold", sans-serif;
            padding: @spacing;
            &:hover {
                cursor: pointer;
            }

            .fas {
                transform: scale(-1, 1);
                transition: transform 0.3s ease-in-out;
            }
        }

        &__body {
            display: none;
        }

        &--active {
            .faq {
                &__header {
                    .fas {
                        transform: scale(1, -1);
                        transition: transform 0.3s ease-in-out;
                    }
                }

                &__body {
                    display: block;
                    padding: 0px @spacing @spacing @spacing;
                }
            }
        }
    }
}
